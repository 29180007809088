import { PowerSettingsNew, Share } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { Copy, Eye, X } from "react-feather";
import TreffasTextField from "src/components/TreffasTextField";
import IconCode from "src/components/svg-icons/IconCode";
import TreffasStyle from "src/utils/TreffasStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const Booking = ({ bookingURL, close, onChangeCategory, type }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarContext();

  return (
    <Box>
      <Box
        sx={{
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => close()}>
            <X size={20} />
          </IconButton>
        </Box>

        <Box sx={{ mt: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Mukta",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "26px",
              lineHeight: "26px",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {type === "website"
              ? t("posts.label.share_your_website")
              : t("package.label.booking.share_your_booking_url")}
          </Typography>
        </Box>

        <Box sx={{ mt: "8px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              textAlign: "center",
              color: "#6E757C",
            }}
          >
            {type === "website"
              ? t("posts.label.share_your_website.description")
              : t("package.label.booking.share_your_booking_url.description")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "32px",
        }}
      >
        <Box>
          <TreffasTextField
            InputProps={{ readOnly: true }}
            value={bookingURL}
            fullWidth
            sx={{
              ...TreffasStyle.formInputLarge("input"),
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px !important",
                height: "46px !important",
                backgroundColor: "#F4F5F6",
              },
              "& .MuiInputBase-input": {
                fontWeight: 500,
                fontSize: "15px",
                color: "#353A3F",
              },
            }}
          />
        </Box>

        <Box sx={{ mt: "8px" }}>
          <CopyToClipboard
            text={bookingURL}
            onCopy={() =>
              showSnackbar(
                t("individual.booking.label.copy.clipboard"),
                "success"
              )
            }
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                minHeight: "44px",
                fontWeight: 700,
                fontSize: "15px",
                lineHeight: "20px",
                borderRadius: "8px",
                ...TreffasStyle.buttonHover,
              }}
              startIcon={<Copy />}
            >
              {t("package.label.booking.copy_url")}
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>

      <Box
        sx={{
          p: "24px 40px 32px",
          backgroundColor: "#F4F5F6",
          mt: "32px",
          display: "flex",
          gap: { xs: "6px", sm: "33px" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF !important",
              width: { xs: "100%", sm: "60px" },
              height: { xs: "100%", sm: "60px" },
            }}
            onClick={() => {
              window.open(bookingURL, "_blank");
            }}
          >
            <Eye color="#000000" />
          </IconButton>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF !important",
              width: { xs: "100%", sm: "60px" },
              height: { xs: "100%", sm: "60px" },
            }}
            onClick={() => onChangeCategory("embed")}
          >
            <IconCode />
          </IconButton>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF !important",
              width: { xs: "100%", sm: "60px" },
              height: { xs: "100%", sm: "60px" },
            }}
            onClick={() => onChangeCategory("share")}
          >
            <Share style={{ fill: "#000000" }} />
          </IconButton>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF !important",
              width: { xs: "100%", sm: "60px" },
              height: { xs: "100%", sm: "60px" },
            }}
            onClick={() => onChangeCategory("button")}
          >
            <PowerSettingsNew style={{ fill: "#000000" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Booking;
